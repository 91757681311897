<template>
  <div class="row justify-content-center" id="Login">
    <div class="col-md-12 log-form">
      <v-img
        style="width: 100%; height: 100%; position: absolute;"
        src="https://cdn.vuetifyjs.com/images/parallax/material.jpg"
      ></v-img>
      <div
        style="position: relative; right:80px; z-index:2; display: flex; flex-direction: row;"
      >
        <v-btn
          @click.prevent="home"
          style="height:50px;width:50px; margin-right: 10px;"
          elevation="5"
          fab
          x-large
          color="white"
          class=" rounded-circle"
          ><v-img
            alt="sanqua multi international"
            class=" rounded-circle"
            contain
            src="../../../public/sanquamulti.png"
            transition="scale-transition"
            style="height:50px;width:50px; "
        /></v-btn>
        <h5 style="line-height:20px; margin-top:12px; color: white;">
          Sanqua
          <h4 style="color:#37474F">Forgoten password</h4>
        </h5>
      </div>
      <v-card
        elevation="10"
        class="mx-auto"
        max-width="444"
        style="padding:30px;"
      >
        <div>
          <h3
            style="display: flex; justify-content: flex-start; align-items:center; width: 130px;"
          >
            Forgot Password
          </h3>
          <p style="font-size: 12px; color: rgba(0, 0, 0, 0.5">
            Please enter your email address. After you submit, we will send you
            the link that you can reset your password.
          </p>
          <b-form @submit.prevent="forgot">
            <label
              for="text-email"
              style="width:100%; text-align:left; padding-left: 10px"
              >Email</label
            >
            <b-form-input
              type="email"
              id="text-email"
              v-model="form.email"
              style="margin-bottom: 20px;"
            ></b-form-input>

            <div class="col-12" style="text-align: right; padding: 0;;">
              <button type="submit">Send request</button>
              <router-link to="/login">Sign in now!</router-link>
            </div>
          </b-form>
        </div>
      </v-card>
    </div>
    <Loader v-if="getIsLoading === true" />
  </div>
</template>

<script>
import Loader from '../../components/common/loader/CircularProgress'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'reset',
  data() {
    return {
      form: {
        email: ''
      }
    }
  },
  components: { Loader },
  computed: {
    ...mapGetters(['getIsLoading'])
  },
  methods: {
    ...mapActions(['emailForgot']),
    ...mapMutations(['setIsLoading']),
    showMsgDialog(pModalType, pStatusMsg) {
      this.$swal
        .fire({
          title:
            pModalType === 'success'
              ? '<strong>Success</strong>'
              : `<strong>${pModalType}</strong>`,
          icon: pModalType,
          html: pStatusMsg,
          timer: 5000
        })
        .then(r => {})
    },
    forgot() {
      if (!this.form.email) {
        this.showMsgDialog('warning', 'Email must be filled')
      } else {
        this.setIsLoading(true)
        this.emailForgot(this.form)
          .then(results => {
            this.setIsLoading(false)
            if (results.data.status_code === '-99') {
              this.showMsgDialog('error', results.data.status_msg)
              this.form.email = ''
            } else {
              if (results.data === '') {
                this.showMsgDialog('error', 'Email not registered')
                this.form.email = ''
              } else {
                this.showMsgDialog('success', results.data.status_msg)
                this.form.email = ''
              }
            }
          })
          .catch(error => {
            this.setIsLoading(false)
            this.showMsgDialog('error', error)
            this.form.email = ''
          })
      }
    }
  }
}
</script>

<style lang="scss" src="../../assets/css/login.scss"></style>
